import React from 'react'

import { logout } from '../../../services/Auth'

// Custom components
import ToggleMenuButton from './ToggleMenuButton'
import { CreateTopBarIcon } from '../../../components'

import './Topbar.css'

const Topbar = ({ onToggleMenu, history }) => {
  const logoutRedirect = () => {
    history.push('/login')
    return logout()
  }
  return (
    <div className='layout-topbar clearfix'>
      <ToggleMenuButton onToggleMenu={onToggleMenu} />

      <div className='layout-topbar-icons'>
        <CreateTopBarIcon
          text='Sign Out'
          iconName='pi-sign-out'
          onClick={logoutRedirect}
        />
      </div>
    </div>
  )
}

export default Topbar
