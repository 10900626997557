import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import { ProgressSpinner } from 'primereact/progressspinner'
import React, { useContext, useRef, useState, useEffect } from 'react'
import { Centralize, GridMaxWidth } from '../../../components'
import { STEPS_NUMS } from '../../../helpers/Complaint'
import { ComplaintContext } from '../../../pages/complaintPage/ComplaintPage'
import { ButtonsCentered } from '../../buttonsCentered/ButtonsCentered'
import { getUploadUrl } from '../../../services/Api'
import './FormAttachment.css'

export const FormAttachment = React.memo(props => {
  const { activeIndex, handleForm, protocolId } = useContext(ComplaintContext)
  const [file, setFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const fileUploader = useRef(null)
  useEffect(() => {
    setFile(null)
  }, [props.viewProtocol])

  if (activeIndex !== STEPS_NUMS.uploadFile) return null
  const _onSelect = async e => {
    const fileTarget = e.files[0]
    setFile(fileTarget)
  }

  const _onUpload = async e => {
    setIsLoading(true)
    const fileTarget = e.files
    if (fileUploader && fileUploader.current) {
      fileUploader.current.state.files = fileTarget
    }
    var arrayUrls = []
    // fake upload
    fileTarget.map(async (item, index) => {
      const typeFile = item.type.split('/')[1]
      const url = await getUploadUrl(
        `${protocolId + `_${index}.` + typeFile}`,
        item.type,
        protocolId
      )

      arrayUrls.push({ ...url, item })
    })
    setTimeout(() => {
      setFile(Object.assign(arrayUrls))
      setIsLoading(false)
    }, 7000)
  }

  const sendFile = () => {
    props.handleFileReport(file)
    setFile(null)
    fileUploader.current.clear()
  }

  return (
    <>
      {isLoading && (
        <div className='progress-container'>
          <ProgressSpinner />
        </div>
      )}
      <Centralize>
        <GridMaxWidth>
          <FileUpload
            ref={fileUploader}
            disabled={isLoading}
            chooseLabel='Anexar arquivo'
            name='attachment'
            accept='*'
            customUpload={true}
            uploadHandler={_onUpload}
            auto={true}
            onSelect={_onSelect}
            maxFileSize={5e7}
            multiple={true}
            invalidFileSizeMessageSummary='{0}: Arquivo muito grande, '
            invalidFileSizeMessageDetail='o tamanho máximo para upload é {0}.'
          />
        </GridMaxWidth>
      </Centralize>

      <ButtonsCentered>
        {props.loading ? (
          <ProgressSpinner />
        ) : (
          <>
            <Button
              label='Voltar'
              className='p-button-secondary'
              disabled={isLoading}
              onClick={() => handleForm(STEPS_NUMS.report)}
            />
            <Button
              label='Finalizar'
              className='p-button-success'
              disabled={isLoading}
              style={{ fontSize: '1.5rem' }}
              onClick={sendFile}
            />
          </>
        )}
      </ButtonsCentered>
    </>
  )
})
