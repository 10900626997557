import { createHashHistory } from 'history'
import React from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'

// Project Pages
import Login from './pages/auth/Login'

// Project Components, Services and Utilities
import { Admin } from './containers'
import ComplaintPage from './pages/complaintPage/ComplaintPage'
import { FinishComplaint } from './pages/finishComplaintPage/finishComplaintPage'
import { ROUTE_NAMES } from './constants'
import { GlobalContextProvider } from './contexts/GlobalContext'
import { isAuthenticated, getIdToken, awsAuthConfig } from './services/Auth'

const history = createHashHistory()

export const RouteWithTheme = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <GlobalContextProvider>
          <Admin {...props}>
            <Component {...props} />
          </Admin>
        </GlobalContextProvider>
      ) : (
        <Redirect to={{ pathname: '/login' }} />
      )
    }
  />
)

const AlternativeRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <GlobalContextProvider>
          <Admin {...props}>
            <Component {...props} />
          </Admin>
        </GlobalContextProvider>
      ) : (
        <Redirect to={{ pathname: '/login' }} />
      )
    }
  />
)

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/' }} />
      )
    }
  />
)

function RouterConfig () {
  const idToken = getIdToken()
  awsAuthConfig(idToken)
  return (
    <Router history={history}>
      <Switch>
        <AuthRoute path={ROUTE_NAMES.loginPage} component={Login} />
        <RouteWithTheme
          exact
          path={ROUTE_NAMES.complaintPage}
          component={ComplaintPage}
        />
        <AlternativeRoute
          exact
          path={ROUTE_NAMES.reportFinish}
          component={FinishComplaint}
        />
      </Switch>
    </Router>
  )
}

export default RouterConfig
