import React, { useEffect } from 'react'
import Auth0Lock from 'auth0-lock'
import {
  isAuthenticated,
  storelogon,
  awsAuthConfig,
  auth0Config
} from '../../services/Auth'
import { BoxOut, BoxIn } from './Styles'
import logo from '../../assets/img/logo_full.png'

const Login = ({ history }) => {
  /**
   * @constant
   * @type {Auth0LockConstructorOptions}
   */
  const auth0Options = {
    allowAutocomplete: false,
    closable: true,
    autoclose: true,
    autofocus: true,
    language: 'pt-br',
    languageDictionary: {
      emailInputPlaceholder: 'something@youremail.com',
      title: 'Área de Login'
    },
    auth: {
      redirect: false,
      responseType: auth0Config.responseType,
      params: {
        scope: auth0Config.scope
      }
    },
    container: 'auth0-lock',
    theme: {
      logo,
      labeledSubmitButton: false,
      primaryColor: 'rgb(35, 98, 141)'
    },
    allowSignUp: false
  }
  /**
   * @constant
   * @type {Auth0LockStatic}
   */
  const lock = new Auth0Lock(
    auth0Config.clientID,
    auth0Config.domain,
    auth0Options
  )

  lock.on('authenticated', authResult => {
    storelogon(authResult)
    awsAuthConfig(authResult.idToken)
    history.push('/')
  })

  if (isAuthenticated()) {
    history.push('/')
  }

  useEffect(() => lock.show(), [lock])

  return (
    <BoxOut className='login-body'>
      <BoxIn id='auth0-lock' className='login-image' />
    </BoxOut>
  )
}

export default Login
