import React from 'react'

export const Wrapper = React.memo(
  ({ component: Component, col = '12', md = '12', emailError, ...props }) => {
    const componentProps = props.componentProps || {}
    const containerProps = componentProps.containerProps || {}
    delete componentProps.containerProps
    return (
      <div className={`p-col-${col} p-md-${md}`}>
        <Component {...props} {...containerProps} />
        {emailError ? <p style={{ color: 'red' }}>Email invalido</p> : null}
      </div>
    )
  }
)
