import styled from 'styled-components'

export const TitleContainer = styled.div`
  &:after {
    content: '';
    display: block;
    border: 1px solid #0388e5
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px
  }
`

export const ContainerCenterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h1`
  font-weight: 700;
  color: black;
  border-color: #0388e5;
`
export const MainTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
