import React from 'react'
// import PropTypes from 'prop-types'

import { InputText } from 'primereact/inputtext'
import { Column } from '../generals'
import { LabelAlignStart } from './inputStyles'

export const InputLabel = React.memo(props => {
  const { icon, componentProps, labelProps = {} } = props

  return (
    <Column>
      <LabelAlignStart
        htmlFor={componentProps.id || componentProps.name}
        className='input-label'
        {...labelProps}
      >
        {componentProps.label}
      </LabelAlignStart>
      <div className='p-inputgroup'>
        {icon && (
          <span className='p-inputgroup-addon'>
            <i className={`pi ${icon}`} />
          </span>
        )}
        <InputText {...componentProps} />
      </div>
    </Column>
  )
})

// InputLabel.propType = {
//     componentProps: PropTypes.instanceOf(InputText),
//     icon: PropTypes.string,
// }
