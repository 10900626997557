import React from 'react'
import { ActionsContainer, SpacingAround } from '../../components'

export const ButtonsCentered = React.memo(({ children }) => {
  return (
    <ActionsContainer>
      <SpacingAround>{children}</SpacingAround>
    </ActionsContainer>
  )
})
