import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Column } from '../generals'
import { LabelAlignStart } from '../inputs/inputStyles'
import { PrimeCheckbox } from './styles'

const useCheckbox = params => {
  const [value, setValue] = useState(params.value || false)

  const onChange = useCallback(({ checked }) => setValue(!!checked), [setValue])

  return {
    ...params,
    value,
    onChange
  }
}

const Checkbox = React.memo(props => {
  return (
    <Column>
      <LabelAlignStart htmlFor={props.id || props.name} className='input-label'>
        <PrimeCheckbox
          inputId={props.id || props.name}
          onChange={props.onChange}
          checked={props.value}
          disabled={props.disabled}
        />
        {props.label}
      </LabelAlignStart>
    </Column>
  )
})

useCheckbox.propType = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  filter: PropTypes.bool,
  disabled: PropTypes.bool
}

export const CheckboxContainer = {
  Checkbox,
  useCheckbox
}
