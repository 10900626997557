import React, { useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { FileUpload } from 'primereact/fileupload'
import { ProgressSpinner } from 'primereact/progressspinner'
import { getUploadUrl } from '../../../services/Api'
import { Button } from 'primereact/button'
import { UploadContainer } from './Styled'

export const UploadFileComplaint = props => {
  const fileUploader = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState(null)

  const hideModal = () => {
    setFile(null)
    fileUploader.current.clear()
    props.setModalUpload(false)
  }
  const _onSelect = async e => {
    const fileTarget = e.files[0]
    setFile(fileTarget)
  }

  const _onUpload = async e => {
    const { protocol } = props.complaint
    setIsLoading(true)
    const fileTarget = e.files
    if (fileUploader && fileUploader.current) {
      fileUploader.current.state.files = fileTarget
    }
    var arrayUrls = []
    // fake upload
    fileTarget.map(async (item, index) => {
      const typeFile = item.type.split('/')[1]
      const url = await getUploadUrl(
        `${protocol + `_${index}.` + typeFile}`,
        item.type,
        protocol
      )
      arrayUrls.push({ ...url, item })
    })
    setTimeout(() => {
      setFile(Object.assign(arrayUrls))
      setIsLoading(false)
    }, 5000)
  }

  const sendFile = () => {
    const { protocol } = props.complaint
    props.UploadFile(file, protocol)
    props.setModalUpload(false)
    fileUploader.current.clear()
  }

  const Footer = (
    <div>
      <Button
        label='Enviar'
        icon='pi pi-check'
        className='p-button-success'
        onClick={sendFile}
        disabled={isLoading || !file}
      />
      <Button
        label='Cancelar'
        icon='pi pi-times'
        className='p-button-danger'
        onClick={hideModal}
      />
    </div>
  )

  return (
    <Dialog
      footer={Footer}
      modal={true}
      header={`Protocolo: ${props.complaint.protocol}`}
      visible={props.modalUpload}
      style={{ width: '50vw' }}
      onHide={hideModal}
    >
      {isLoading && (
        <div className='progress-container'>
          <ProgressSpinner style={{ width: '30px', height: '30px' }} />
        </div>
      )}
      <UploadContainer>
        <FileUpload
          ref={fileUploader}
          disabled={isLoading}
          chooseLabel='Anexar arquivo'
          name='attachment'
          accept='*'
          customUpload={true}
          uploadHandler={_onUpload}
          auto={true}
          onSelect={_onSelect}
          maxFileSize={5e7}
          multiple={true}
          invalidFileSizeMessageSummary='{0}: Arquivo muito grande, '
          invalidFileSizeMessageDetail='o tamanho máximo para upload é {0}.'
        />
      </UploadContainer>
    </Dialog>
  )
}
