import React from 'react'
import { InputTextarea } from 'primereact/inputtextarea'
import PropTypes from 'prop-types'
import { Column } from '../generals'
import { LabelAlignStart } from './inputStyles'

export const TextArea = props => {
  const { componentProps } = props

  return (
    <Column>
      <LabelAlignStart htmlFor={componentProps.id || componentProps.name}>
        {componentProps.label}
      </LabelAlignStart>
      <InputTextarea {...componentProps} />
    </Column>
  )
}

TextArea.propType = {
  componentProps: PropTypes.instanceOf(InputTextarea).isRequired
}
