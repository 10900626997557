import Styled from 'styled-components'

export const ItemList = Styled.div`
    display: grid;
    flex-direction: row;  
    justify-container: space-between;
    grid-template-columns: 90% auto auto auto;
    border-bottom: 1px solid;
`

export const ButtonView = Styled.div`
    display: flex;
    flex-direction: row;
`

export const ComplaintEmpty = Styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center
`
