import React, { useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Column } from '../generals'
import { LabelAlignStart } from '../inputs/inputStyles'
import { PrimeDropdown } from './styles'

const useDropdown = params => {
  const [value, setValue] = useState(null)
  const [list, setList] = useState(params.list)

  const onChange = useCallback(event => setValue(event.value), [setValue])

  useEffect(() => setList(params.list), [params.list])

  const dynamicStates = useMemo(() => {
    const hasLength = !!list && list.length > 0
    const loading = !hasLength

    return {
      placeholder: loading
        ? 'Carregando...'
        : hasLength
        ? 'Pesquise e/ou Selecione...'
        : 'Não há dados',
      suggestions: !loading && !hasLength ? ['Não há dados.'] : list,
      disabled: loading
    }
  }, [list])

  return {
    ...params,
    value,
    onChange: a => {
      if (typeof params.onChange === 'function') {
        params.onChange(a)
      }
      return onChange(a)
    },
    ...dynamicStates
  }
}

const Dropdown = React.memo(props => {
  return (
    <Column>
      <LabelAlignStart htmlFor={props.name}>{props.label}</LabelAlignStart>
      <PrimeDropdown
        inputId={props.name}
        name={props.name}
        options={props.list}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        disabled={props.disabled}
        required={props.required}
        filter={props.filter}
        filterBy={props.filterBy}
        dataKey={props.key}
      />
    </Column>
  )
})

useDropdown.propType = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  filter: PropTypes.bool
}

export const DropdownContainer = {
  Dropdown,
  useDropdown
}
