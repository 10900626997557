import Styled from 'styled-components'

export const DeleteContainer = Styled.div`
    display: flex;
    aligh-items: center;
    justify-content: center
`
export const UploadContainer = Styled.div`
    display: flex;
    aligh-items: center;
    justify-content: center
`
