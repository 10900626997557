import * as AWS from 'aws-sdk'
import Storage from './Storage'

const authStorage = Storage('auth')

AWS.config.region = 'us-east-2'

export const auth0Config = {
  clientID:
    process.env.REACT_APP_AUTH0_CLIENT_ID || 'z0VwMd4qkx3egVUpEV5pTrADancj7oFg',
  domain: 'eticca.auth0.com',
  responseType: 'token id_token',
  scope: 'openid profile email'
}

/**
 * check if user is authenticated, checking expiration
 */
export const isAuthenticated = () => {
  const expiresAt = authStorage.get('expires_at')
  if (!expiresAt) {
    return false
  }
  return new Date().getTime() < expiresAt
}

/**
 * store logon result in localstorage
 * @param {AuthResult} authResult
 */
export const storelogon = authResult => {
  const expiresAt = authResult.expiresIn * 1000 + Date.now()
  authStorage.set('access_token', authResult.accessToken)
  authStorage.set('id_token', authResult.idToken)
  authStorage.set('user_payload', authResult.idTokenPayload)
  authStorage.set('expires_at', expiresAt)
}

/**
 * config aws credentials
 * @param {AuthResult} authResult
 */
export const awsAuthConfig = idToken => {
  AWS.config.credentials = new AWS.WebIdentityCredentials({
    RoleArn: 'arn:aws:iam::240969009095:role/report_app_developer',
    ProviderId: 'arn:aws:iam::240969009095:oidc-provider/xdevel.auth0.com/',
    WebIdentityToken: idToken
  })
}

/**
 * clear storage for logout process
 */
export const logout = () => {
  authStorage.clear()
}

/**
 * get the current user access token
 */
export const getIdToken = () => authStorage.get('id_token')

/**
 * return user payload info from JWT auth
 */
export const getUserInfo = () => {
  if (!isAuthenticated()) {
    return {}
  }
  const strUserPayload = authStorage.get('user_payload')
  if (!strUserPayload) {
    return {}
  }
  return strUserPayload
}
