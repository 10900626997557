import React, { useState } from 'react'
import { RadioButton } from 'primereact/radiobutton'
import PropTypes from 'prop-types'

const useMultipleRadioButtons = initialValue => {
  const [radioValue, setRadioValue] = useState(initialValue)

  return {
    value: radioValue,
    onChange: ({ value }) => setRadioValue(value)
  }
}

const useRadioButton = params => {
  const [checked, setChecked] = useState(params.initialValue || false)

  const _checked =
    params.value === undefined ? checked : params.value === params.label
  const _onChange = ({ checked }) => setChecked(checked)

  return {
    value: params.label,
    inputId: params.inputId || params.label,
    checked: _checked,
    onChange: params.onChange || _onChange
  }
}

const CustomRadioButton = React.memo(props => {
  const { radioButtonProps, labelProps = {} } = props
  return (
    <div style={props.contairnerStyle || {}}>
      <RadioButton {...radioButtonProps} />
      <label
        htmlFor={radioButtonProps.inputId}
        className='p-radiobutton-label'
        style={labelProps}
      >
        {radioButtonProps.value}
      </label>
    </div>
  )
})

useRadioButton.propType = {
  initialValue: PropTypes.bool,
  label: PropTypes.string.isRequired,
  inputId: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
}
CustomRadioButton.propType = {
  radioButtonProps: PropTypes.object,
  labelProps: PropTypes.object,
  inputId: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired
}

export const RadioButtonWrapper = {
  useRadioButton,
  RadioButton: CustomRadioButton,
  useMultipleRadioButtons
}
