const clear = masterKey => {
  localStorage.setItem(masterKey, JSON.stringify({}))
}

const set = (masterKey, key, value) => {
  try {
    const masterObj = JSON.parse(localStorage.getItem(masterKey))
    masterObj[key] = value
    localStorage.setItem(masterKey, JSON.stringify(masterObj))
  } catch (e) {
    clear(masterKey)
    return set(masterKey, key, value)
  }
}

const get = (masterKey, key) => {
  try {
    const masterObj = JSON.parse(localStorage.getItem(masterKey))
    return masterObj[key]
  } catch (e) {
    clear(masterKey)
    return get(masterKey, key)
  }
}

const Storage = masterKey => ({
  get: key => get(masterKey, key),
  set: (key, value) => set(masterKey, key, value),
  clear: () => clear(masterKey)
})

export default Storage
