import Styled from 'styled-components'

export const Protocol = Styled.h1`
    color: black;
    font-size: 2.5em;
    font-weight: 600;
    background: #e4e6f0;
    width: fit-content;
    padding: 20px;
    border-radius: 20px;
    letter-spacing: .3rem;
`

export const ProtocolLabel = Styled.h1`
    font-size: 34px;
`
