import Styled from 'styled-components'
import PropTypes from 'prop-types'

export const Row = Styled.div`
    display: flex;
}`

export const Column = Styled.div`
    display: flex;
    flex-direction: column;
}`

export const Center = Styled(Row)`
    justify-content: center;
    padding-top: 5px;
`

export const SpacingAround = Styled(Row)`
    justify-content: space-around;
    min-width: 300px;
`

export const GridMaxWidth = Styled.div.attrs({
  className: 'p-grid'
})`
    ${({ width, margin }) => `
        max-width: ${width || 500}px;
        margin: ${margin || '20px 0px 0px'} !important;
    `}
`

GridMaxWidth.defaultProps = {
  width: 500,
  margin: '20px 0px 0px'
}
GridMaxWidth.propType = {
  width: PropTypes.number,
  margin: PropTypes.string
}
