import Styled from 'styled-components'
import { Dropdown } from 'primereact/dropdown'

export const PrimeDropdown = Styled(Dropdown)`
  display: flex !important;
  text-align: left;
  .p-dropdown-filter-container {
    width: 100%;
  }
  input {
    width: 94% !important;
  }

  .p-autocomplete-loader {
    display: none !important;
  }

  @media (min-width: 500px) {
    input {
      width: 100% !important;
    }
  }
`
