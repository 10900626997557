import Styled from 'styled-components'

export const UnderStepsContainer = Styled.div`
    position: relative;
    min-height: 63vh;
    text-align: center;
    
    @media (min-width: 500px) {
        padding-top: 1.8rem;
    }
}
`
