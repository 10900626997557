import Styled from 'styled-components'
import { Row } from '../generals'

export const ActionsContainer = Styled(Row)`
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 2.5rem;   
    max-height: 3.5rem;    
    margin-bottom: 2.5rem;
    right: 0px;
 
    @media (min-width: 1023px) {
        left: 12%
    }
    @media (min-width: 1060px) {
        left: 11%
    }
    @media (min-width: 1440px) {
        left: 9%
    }
`
