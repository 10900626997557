/* eslint-disable */

import { useContext, useCallback } from 'react'
import { GlobalContext } from '../contexts/GlobalContext'
import { MESSAGES } from '../constants'

export const useGrowl = () => {
  const { showGrowlMsg } = useContext(GlobalContext)

  const showGenericFieldError = useCallback(parameter => {
    if (parameter) {
      showGrowlMsg(MESSAGES.ERROR.errorSend)
      return
    }
    showGrowlMsg(MESSAGES.ERROR.gerericFieldError)
  }, [])

  return { showGrowlMsg, showGenericFieldError }
}
