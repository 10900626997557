import styled from 'styled-components'
import { Steps } from 'primereact/steps'
import { PERCENTAJE_BY_STEP } from '../../helpers/Complaint'

export const CustomSteps = styled(Steps)`
  .p-steps-item {
    width: 16% !important;
    font-size: 60% !important;
  }
  ul {
    margin-left: 15% !important;
  }
  &::before {
    border-top: 0 !important;
    width: 55% !important;
    height: 7px !important;
    left: 22.5% !important;
    /* Dynamic styling */
    ${({ activeIndex }) => {
      const porcentajeCompleted = PERCENTAJE_BY_STEP[activeIndex - 1] || 0
      const porcentajeCompleting = PERCENTAJE_BY_STEP[activeIndex] % 5
      return `
        background-image: -webkit-linear-gradient(left, #34a835 ${porcentajeCompleted +
          1}%, #34a83570 ${porcentajeCompleting}%, #c8c8c86b 1%) !important;
      `
    }}
  }

  .p-disabled {
    opacity: 0.76 !important;
  }

  .p-disabled .p-steps-number {
    border: none !important;
  }

  .p-menuitem-link {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    cursor: default;
    outline: 0;
    box-sizing: border-box;
    box-shadow: none !important;
  }

  .p-steps-number {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 1.8em !important;
    font-weight: bold !important;
    color: black !important;
    padding: 1.5em !important;
    background: #e6e6e6 !important;
  }
  .p-steps-item.p-highlight .p-steps-number {
    background: #55afed !important;
    color: white !important;
    border: 2px solid #0000003d !important;
    box-shadow: 1px 1px 12px 1px #00000066;
  }
  .p-steps-item.p-step-completed .p-steps-number {
    background: #34a835 !important;
    color: white !important;
    border: 0px solid transparent !important;
  }

  @media (max-width: 480px) {
    margin-bottom: 15px;
    .p-steps-item {
      width: 28% !important;
    }
    &::before {
      content: none !important;
    }
    ul::after {
      content: none !important;
    }
    ul {
      display: flex !important;
      flex-wrap: wrap !important;
      justify-content: space-around !important;
    }
  }
`
