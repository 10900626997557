import React, { useContext, useCallback, useMemo, useEffect } from 'react'
import { ComplaintContext } from '../../pages/complaintPage/ComplaintPage'
import {
  STEPS_NUMS,
  CONTAINER_FORM_KEYS,
  checkRequiredFields,
  setPrevValues
} from '../../helpers/Complaint'
import { Button } from 'primereact/button'
import {
  Centralize,
  DropdownContainer,
  CheckboxContainer,
  GridMaxWidth,
  Inputs,
  Wrapper
} from '../../components'
import { ButtonsCentered } from '../buttonsCentered/ButtonsCentered'
import { getValueFromRefsByKey } from '../../utils/generals'
import { FIELDS } from '../../helpers/Complaint'
// import { useFetch } from '../../hooks/usefetch';

const { Dropdown, useDropdown } = DropdownContainer
const { Checkbox, useCheckbox } = CheckboxContainer
const { useUncontrolledInput, TextArea } = Inputs

const {
  isRelatedProps,
  // inputRelatedFactsProps,
  inputBadPersonsProps,
  inputWitnessProps,
  inputReportProblemProps,
  inputEvidenceProps
  // inputExtratInfosProps
} = FIELDS.DESCRIPTION

// COMPONENT
const ReportForm = React.memo(props => {
  // const { response, loading } = useFetch({url: 'type of report list URL', options})
  const originReport = useMemo(
    () => ({
      label: 'origem da denúncia',
      list: [
        {
          label: 'E-mail',
          value: 'E-mail'
        },
        {
          label: '0800',
          value: '0800'
        }
      ],
      name: 'originType',
      required: true,
      filter: true,
      filterPlaceholder: 'Pesquisar tipos da denuncia'
    }),
    []
  )

  const reportTypeFieldProps = useMemo(
    () => ({
      label: 'Tipo de relato',
      list: [
        {
          label: 'Assédio moral ou agressão física',
          value: 'BULLYING_OR_AGGRESSION'
        },
        { label: 'Assédio sexual', value: 'SEXUAL_HARASSMENT' },
        { label: 'Acidente não reportado', value: 'UNREPORTED_ACCIDENT' },
        { label: 'Desvio de comportamento', value: 'BEHAVIOR_DEVIATION' },
        { label: 'Discriminação', value: 'DISCRIMINATION' },
        {
          label: 'Distorções em demonstrações financeiras',
          value: 'MISSTATEMENTS_FINANCIAL_STATEMENTS'
        },
        {
          label: 'Descumprimento de normas e políticas internas',
          value: 'NON_COMPLIANCE_INTERNAL_RULES'
        },
        {
          label: 'Favorecimento ou conflito de interesse',
          value: 'CONFLICT_INTERESTS'
        },
        { label: 'Fraude', value: 'FRAUD' },
        { label: 'Lavagem de dinheiro', value: 'MONEY_LAUNDRY' },
        {
          label: 'Relacionamento íntimo com subordinação direta',
          value: 'INTIMATE_RELATIONSHIP'
        },
        { label: 'Roubo, furto ou desvio de materiais', value: 'THEFT' },
        {
          label: 'Uso ou tráfico de substâncias proibidas',
          value: 'DRUG_TRAFFIC'
        },
        {
          label: 'Uso indevido de bens e recursos',
          value: 'ADMINISTRATIVE_IMPROBITY'
        },
        {
          label: 'Vazamento ou uso indevido de informações',
          value: 'INFORMATION_LEAK'
        },
        {
          label: 'Violação de leis ambientais',
          value: 'ENVIRONMENT_LAW_VIOLATION'
        },
        {
          label: 'Violação de leis trabalhistas',
          value: 'LABOR_LAW_VIOLATION'
        },
        {
          label: 'Violação de leis não explícitas nas demais categorias',
          value: 'UNETHICAL'
        },
        {
          label: 'Pagamento ou recebimento impróprios (corrupção)',
          value: 'CORRUPTION'
        },
        { label: 'Outros', value: 'OTHERS' }
      ],
      name: 'reportType',
      required: true,
      filter: true,
      filterPlaceholder: 'Pesquisar tipos de relato'
    }),
    [
      /** response */
    ]
  )
  const reportTypeField = useDropdown(reportTypeFieldProps)
  const isRelatedField = useCheckbox(isRelatedProps)
  const reportOrigin = useDropdown(originReport)
  // const relatedFactsField = useUncontrolledInput(inputRelatedFactsProps)
  const badPersonsField = useUncontrolledInput(inputBadPersonsProps)
  const witnessField = useUncontrolledInput(inputWitnessProps)
  const repotProblemField = useUncontrolledInput(inputReportProblemProps)
  const evidenceField = useUncontrolledInput(inputEvidenceProps)
  // const extratInfosField = useUncontrolledInput(inputExtratInfosProps)

  useEffect(
    setPrevValues(props.formData[CONTAINER_FORM_KEYS.report], [
      reportTypeField,
      reportOrigin,
      isRelatedField,
      // relatedFactsField,
      badPersonsField,
      witnessField,
      repotProblemField,
      evidenceField
      // extratInfosField
    ]),
    []
  )

  return (
    <>
      <Centralize>
        <GridMaxWidth>
          <Wrapper component={Dropdown} {...reportTypeField} />
          <Wrapper component={Checkbox} {...isRelatedField} />
          <Wrapper component={Dropdown} {...reportOrigin} />
          {/* <Wrapper component={TextArea} componentProps={relatedFactsField} /> */}
          <Wrapper component={TextArea} componentProps={badPersonsField} />
          <Wrapper component={TextArea} componentProps={witnessField} />
          <Wrapper component={TextArea} componentProps={repotProblemField} />
          <Wrapper component={TextArea} componentProps={evidenceField} />
          {/* <Wrapper component={TextArea} componentProps={extratInfosField} /> */}
        </GridMaxWidth>
      </Centralize>

      <ButtonsCentered>
        <Button
          label='Voltar'
          className='p-button-secondary'
          onClick={props.handleBackButton}
        />
        <Button
          label='Continuar'
          onClick={() =>
            props.handleReportForm([
              reportTypeField,
              reportOrigin,
              isRelatedField,
              // relatedFactsField.ref,
              badPersonsField.ref,
              witnessField.ref,
              repotProblemField.ref,
              evidenceField.ref
              // extratInfosField.ref
            ])
          }
        />
      </ButtonsCentered>
    </>
  )
})

export function FormReport () {
  const {
    activeIndex,
    handleForm,
    showGenericFieldError,
    formData
  } = useContext(ComplaintContext)
  const handleBackButton = useCallback(() => {
    handleForm(STEPS_NUMS.company)
  }, [handleForm])

  const handleReportForm = useCallback(
    fieldRefs => {
      if (checkRequiredFields(fieldRefs)) return showGenericFieldError()
      handleForm(STEPS_NUMS.uploadFile, {
        [CONTAINER_FORM_KEYS.report]: getValueFromRefsByKey(fieldRefs)
      })
    },
    [handleForm, showGenericFieldError]
  )

  if (activeIndex !== STEPS_NUMS.report) return null

  return (
    <ReportForm
      handleBackButton={handleBackButton}
      handleReportForm={handleReportForm}
      formData={formData}
    />
  )
}
