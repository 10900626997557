import Styled from 'styled-components'
import { AutoComplete } from 'primereact/autocomplete'

export const PrimeAutoComplete = Styled(AutoComplete)`
    display: flex !important;
    input {
        width: 94% !important;
    }

    .p-autocomplete-loader {
        display: none !important; 
    }

    @media (min-width: 500px) {
        input {
            width: 100% !important;
        }
    }
`
