export const getValueFromRefsByKey = (inputRefs, key = 'name') => {
  return inputRefs.reduce((acc, crr, idx) => {
    if (
      crr.current &&
      crr.current.props &&
      crr.current.props.name === 'phone'
    ) {
      crr = {
        current: {
          ...crr.current,
          element: {
            value: crr.current.input.value,
            name: crr.current.props.name
          }
        }
      }
    }
    const nameAsKey =
      crr[key] ||
      crr.current.props[key] ||
      crr.current.element[key] ||
      `${key}_${idx}`
    const value =
      crr.value !== undefined ? crr.value : crr.current.element.value
    return { ...acc, [nameAsKey]: value }
  }, {})
}
export const mapAsync = async (arr, cb) => {
  const result = []
  for (let i = 0; i < arr.length; i++) {
    result.push(await cb(arr[i], i))
  }
  return result
}
