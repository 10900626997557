import { useRef, useState } from 'react'

export const useUncontrolledInput = (params = {}) => {
  const { initialValue = '' } = params

  const inputRef = useRef({ current: { value: initialValue } })

  return {
    ref: inputRef,
    ...params
  }
}

export const useControlledInput = (params = {}) => {
  const { initialValue = '', onChange } = params

  const [value, setValue] = useState(initialValue)

  return {
    value,
    onChange: onChange || (({ target }) => setValue(target.value)),
    ...params
  }
}
