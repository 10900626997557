import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { InputMask } from 'primereact/inputmask'
import { SelectButton } from 'primereact/selectbutton'
import PropTypes from 'prop-types'

const maskType = [
  { label: 'Fixo', value: true },
  { label: 'Celular', value: false }
]

export const InputIcon = props => {
  const { icon, componentProps } = props
  const [typeMask, setTypeMask] = useState(true)

  return (
    <div>
      <div className='p-inputgroup'>
        <span className='p-inputgroup-addon'>
          <i className={`pi ${icon}`} />
        </span>
        {!componentProps.mask ? (
          <InputText {...componentProps} />
        ) : (
          <InputMask
            {...componentProps}
            mask={typeMask ? '(99) 9999-9999' : '(99) 99999-9999'}
          />
        )}
      </div>
      {componentProps.mask ? (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <SelectButton
            style={{ marginTop: '2px' }}
            value={typeMask}
            options={maskType}
            onChange={e => setTypeMask(e.value)}
          />
        </div>
      ) : null}
    </div>
  )
}

InputIcon.propType = {
  componentProps: PropTypes.instanceOf(InputText),
  icon: PropTypes.string
}
