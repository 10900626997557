import React, { createContext, useRef, useMemo, useCallback } from 'react'
import { Growl } from 'primereact/growl'

export const GlobalContext = createContext({})

export const GlobalContextProvider = ({ children }) => {
  const growlRef = useRef(null)

  const showGrowlMsg = useCallback(
    ([severity, summary, detail, growlProps = {}]) => {
      growlRef.current.show({ severity, summary, detail, ...growlProps })
      return growlRef
    },
    [growlRef]
  )

  const contextValue = useMemo(
    () => ({
      showGrowlMsg
    }),
    [showGrowlMsg]
  )

  return (
    <>
      <Growl ref={growlRef} />
      <GlobalContext.Provider value={contextValue}>
        {children}
      </GlobalContext.Provider>
      )
    </>
  )
}
