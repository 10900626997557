import React, { useContext, useState, useEffect } from 'react'
import Styled from 'styled-components'

import { ComplaintContext } from './ComplaintPage'
import moment from '../../utils/moment'

const Span = Styled.span`
  color: #333;
  position: fixed;
  font-size: .7rem;
  bottom: 5px;
  span.time {
    font-weight: 600;
    text-transform: uppercase;
  }
`

export default function ReportTime () {
  const [count, setCount] = useState(1)

  const { reportTime } = useContext(ComplaintContext)

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1)
    }, 5000)
    return () => clearInterval(interval)
  }, [count])

  return (
    <Span>
      Tempo no protocolo atual:{' '}
      <span className='time'>{count && moment(reportTime).toNow(true)}</span>
    </Span>
  )
}
