import React from 'react'
// import { InputText } from 'primereact/inputtext'

export const CreateTopBarIcon = React.memo(
  ({ text = 'icon', iconName, badgValue, onClick }) => {
    return (
      <button className='p-link' onClick={onClick}>
        <span className='layout-topbar-item-text'>{text}</span>
        <span className={`layout-topbar-icon pi ${iconName}`} />
        {badgValue !== undefined && (
          <span className='layout-topbar-badge'>{badgValue}</span>
        )}
      </button>
    )
  }
)
