import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Column } from '../generals'
import { LabelAlignStart } from '../inputs/inputStyles'
import { PrimeAutoComplete } from './styles'

const useAutoComplete = params => {
  const [value, setValue] = useState(null)
  const [suggestionsFiltered, setSuggestionFiltered] = useState(params.list)

  const completeMethod = useCallback(
    event => {
      const stringToQuery = (event.query || '').toLowerCase()
      const results = (params.list || []).filter(item =>
        item.toLowerCase().includes(stringToQuery)
      )

      setSuggestionFiltered(results)
    },
    [setSuggestionFiltered, params.list]
  )

  const onChange = useCallback(event => setValue(event.value), [setValue])

  const dynamicStates = useMemo(() => {
    const loading = !params.list
    const listLength = !loading && params.list.length

    return {
      placeholder: loading
        ? 'Carregando...'
        : listLength
        ? 'Filtre e/ou Selecione...'
        : 'Não há dados',
      suggestions:
        !loading && !listLength ? ['Não há dados.'] : suggestionsFiltered,
      disabled: loading
    }
  }, [suggestionsFiltered, params.list])

  return {
    ...params,
    value,
    completeMethod,
    onChange,
    ...dynamicStates
  }
}

const AutoComplete = React.memo(props => {
  return (
    <Column>
      <LabelAlignStart htmlFor={props.name}>{props.label}</LabelAlignStart>
      <PrimeAutoComplete
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        suggestions={props.suggestions}
        completeMethod={props.completeMethod}
        delay={100}
        placeholder={props.placeholder}
        dropdown
        disabled={props.disabled}
      />
    </Column>
  )
})

useAutoComplete.propType = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.string
}

export const AutocompleteContainer = {
  AutoComplete,
  useAutoComplete
}
