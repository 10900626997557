import Styled from 'styled-components'

export const BoxOut = Styled.div`
  display: flex;
  height: 100%;
`

export const BoxIn = Styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
`
