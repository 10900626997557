import React, { useContext } from 'react'
import { ComplaintContext } from '../../../pages/complaintPage/ComplaintPage'
import { STEPS_NUMS } from '../../../helpers/Complaint'
import { Button } from 'primereact/button'
import { Protocol, ProtocolLabel, Centralize } from '../../../components'
import { ButtonsCentered } from '../../buttonsCentered/ButtonsCentered'

export function ShowProtocol (props) {
  const { activeIndex, handleForm, protocolId } = useContext(ComplaintContext)
  const getProtocol = () => {
    props.setViewProtocol(true)
    props.resetForm()
  }

  if (activeIndex !== STEPS_NUMS.protocol) return null
  return (
    <>
      <ProtocolLabel>Protocolo</ProtocolLabel>

      <Centralize>
        {!props.viewProtocol ? (
          <Button label='Gerar Protocolo' onClick={getProtocol} />
        ) : (
          <Protocol>{protocolId}</Protocol>
        )}
      </Centralize>

      <ButtonsCentered>
        <Button
          label='Continuar'
          onClick={() => handleForm(STEPS_NUMS.identification)}
          disabled={!props.viewProtocol}
        />
      </ButtonsCentered>
    </>
  )
}
