// /* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useCallback, useEffect, useState } from 'react'
import { ComplaintContext } from '../../pages/complaintPage/ComplaintPage'
import { Button } from 'primereact/button'
import {
  STEPS_NUMS,
  CONTAINER_FORM_KEYS,
  setPrevValues
} from '../../helpers/Complaint'
import {
  Column,
  Centralize,
  SpacingAround,
  RadioButtonWrapper,
  Inputs,
  GridMaxWidth,
  Wrapper
} from '../../components'
import { ButtonsCentered } from '../buttonsCentered/ButtonsCentered'
import { getValueFromRefsByKey } from '../../utils/generals'
import { FIELDS } from '../../helpers/Complaint'

const { InputIcon, useUncontrolledInput } = Inputs
const { RadioButton } = RadioButtonWrapper

const {
  inputNameProps,
  inputEmailProps,
  inputNumberProps,
  radioButton_yes,
  radioButton_no
} = FIELDS.IDENTIFICATION

// COMPONENT
const IdentificationForm = React.memo(props => {
  const [emailError, setEmailError] = useState(false)

  const prevIdentificationForm =
    props.formData[CONTAINER_FORM_KEYS.identification]

  const radioButtonInitialValue = prevIdentificationForm
    ? radioButton_yes.label
    : radioButton_no.label
  const radioButtonChecked = RadioButtonWrapper.useMultipleRadioButtons(
    radioButtonInitialValue
  )

  const radioButtonPositive = RadioButtonWrapper.useRadioButton({
    ...radioButton_yes,
    ...radioButtonChecked
  })
  const radioButtonNegative = RadioButtonWrapper.useRadioButton({
    ...radioButton_no,
    ...radioButtonChecked
  })

  const inputName = useUncontrolledInput(inputNameProps)
  const inputEmail = useUncontrolledInput(inputEmailProps)
  const inputNumber = useUncontrolledInput(inputNumberProps)

  // set previous values
  useEffect(
    setPrevValues(
      prevIdentificationForm,
      [inputName, inputEmail, inputNumber],
      prevIdentificationForm &&
        radioButtonChecked.value === radioButton_yes.label
    ),
    [radioButtonChecked]
  )

  return (
    <>
      <Column>
        <h2 style={{ fontWeight: 'bold' }}>Gostaria de se identificar?</h2>
        <Centralize style={{ marginBottom: '3.5em' }}>
          <SpacingAround>
            <RadioButton radioButtonProps={radioButtonPositive} />
            <RadioButton radioButtonProps={radioButtonNegative} />
          </SpacingAround>
        </Centralize>
        {radioButtonChecked.value === radioButton_yes.label && (
          <Centralize>
            <GridMaxWidth>
              <Wrapper
                component={InputIcon}
                icon='pi-user'
                componentProps={inputName}
              />
              <Wrapper
                md={6}
                component={InputIcon}
                icon='pi-bookmark'
                componentProps={inputEmail}
                emailError={emailError}
              />
              <Wrapper
                md={6}
                component={InputIcon}
                icon='pi-pencil'
                componentProps={inputNumber}
              />
            </GridMaxWidth>
          </Centralize>
        )}
      </Column>

      <ButtonsCentered>
        <Button
          label='Voltar'
          className='p-button-secondary'
          onClick={props.handelCancelForm}
        />
        <Button
          label='Continuar'
          onClick={() =>
            props.handleIdentificationForm(
              radioButtonChecked.value,
              [inputName.ref, inputEmail.ref, inputNumber.ref],
              setEmailError,
              radioButtonNegative
            )
          }
        />
      </ButtonsCentered>
    </>
  )
})

export function FormIdentity () {
  const { activeIndex, handleForm, formData } = useContext(ComplaintContext)

  const handelCancelForm = useCallback(() => {
    handleForm(STEPS_NUMS.protocol)
  }, [handleForm])

  const handleIdentificationForm = useCallback(
    (buttonCheckedValue, formData, setEmailError, radioButtonNegative) => {
      const identifyUser = buttonCheckedValue === radioButton_yes.label

      if (radioButtonNegative.checked === true) {
        handleForm(STEPS_NUMS.company, {
          [CONTAINER_FORM_KEYS.identification]: {
            name: '',
            email: '',
            phone: ''
          }
        })
        return
      }
      const getValue = getValueFromRefsByKey(formData, 'name')
      if (getValue.email) {
        if (!getValue.email.includes('@')) {
          setEmailError(true)
          setTimeout(() => setEmailError(false), 5000)
          return
        }
      }

      if (!identifyUser)
        return handleForm(STEPS_NUMS.company, {
          [CONTAINER_FORM_KEYS.identification]: undefined
        })

      handleForm(STEPS_NUMS.company, {
        [CONTAINER_FORM_KEYS.identification]: getValue
      })
    },
    [handleForm]
  )

  if (activeIndex !== STEPS_NUMS.identification) return null

  return (
    <IdentificationForm
      handelCancelForm={handelCancelForm}
      handleIdentificationForm={handleIdentificationForm}
      formData={formData}
    />
  )
}
