import React from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

export const SucessDialog = props => {
  const Footer = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        label='Ok'
        icon='pi pi-check'
        className='p-button-success'
        onClick={props.newProtocol}
      />
    </div>
  )

  return (
    <Dialog
      footer={Footer}
      modal={true}
      header={`Protocolo: ${props.protocol}`}
      visible={props.sucessModal}
      style={{ width: '50vw' }}
      onHide={() => null}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <i
          className='pi pi-check-circle'
          style={{ fontSize: '12vh', color: '#34A835' }}
        />
        <h4>{props.modalText}</h4>
      </div>
    </Dialog>
  )
}
