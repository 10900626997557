import React from 'react'
import { MinLoadginContainer } from './styles'

export const MinLoading = ({
  loading,
  label = 'Carregando...',
  style = {}
}) => {
  if (!loading) return null

  return (
    <MinLoadginContainer style={style}>
      {label}
      <i className='pi pi-spin pi-spinner' style={{ fontSize: '3em' }} />
    </MinLoadginContainer>
  )
}
