import { CLASSES } from '../constants'

// Constants
export const STEPS_NUMS = {
  protocol: 0,
  identification: 1,
  company: 2,
  report: 3,
  uploadFile: 4
}
export const CONTAINER_FORM_KEYS = {
  protocolId: 'protocolId', // string
  identification: 'identification', // obj
  company: 'company', // obj
  report: 'description', // obj
  file: 'file' // obj file
}

export const PERCENTAJE_BY_STEP = [20, 44.4, 68.6, 93, 100]
export const STEP_STYLE_BY_STATE = {
  completed: 'p-steps-item p-step-completed',
  selected: 'p-steps-item p-highlight p-steps-current'
}

const className = CLASSES.fullWidth
const textAreacommonProps = {
  className: CLASSES.fullWidth,
  rows: 3,
  cols: 30
}

export const FIELDS = {
  IDENTIFICATION: {
    inputNameProps: {
      placeholder: 'Nome',
      name: 'name',
      className
    },
    inputEmailProps: {
      placeholder: 'Email',
      type: 'email',
      name: 'email',
      className
    },
    inputNumberProps: {
      name: 'phone',
      placeholder: 'Numero de contato',
      mask: '(99) 9999-9999',
      className
    },

    radioButton_yes: {
      label: 'Sim',
      inputId: 'yes'
    },

    radioButton_no: {
      label: 'Não',
      inputId: 'no'
    }
  },

  COMPANY: {
    inputCityProps: {
      label: 'Cidade',
      placeholder: 'Ex.: Vitória',
      name: 'city',
      required: true,
      containerProps: {
        icon: 'pi-user'
      },
      className
    },
    inputFilialProps: {
      label: 'Filial',
      name: 'filial',
      containerProps: {
        icon: 'pi-user'
      },
      className
    },
    inputDepartmentProps: {
      label: 'Departamento',
      name: 'department',
      containerProps: {
        icon: 'pi-user'
      },
      className
    }
  },

  DESCRIPTION: {
    isRelatedProps: {
      label: 'Denunciante está relacionado nos fatos ou com os envolvidos?',
      name: 'isRelated',
      id: 'isRelated'
    },
    inputBadPersonsProps: {
      label: 'Quem são os supostos malfeitores envolvidos ?',
      name: 'involved',
      ...textAreacommonProps
    },
    inputRelatedFactsProps: {
      label: 'Fatos relacionados entre o denunciante e a denúncia',
      name: 'relatedFacts',
      ...textAreacommonProps
    },
    inputWitnessProps: {
      label: 'Existe alguma testemunha ?',
      name: 'witness',
      ...textAreacommonProps
    },

    inputReportProblemProps: {
      label: 'Que problema gostaria de relatar ?',
      name: 'problem',
      required: true,
      ...textAreacommonProps
    },
    inputEvidenceProps: {
      label: 'Existe alguma evidência ?',
      name: 'evidence',
      ...textAreacommonProps
    },
    inputExtratInfosProps: {
      label: 'Existe mais alguma informação que julgue ser relevante ao caso ?',
      name: 'observation',
      ...textAreacommonProps
    }
  }
}

export const handleStepsStyles = clear => {
  const allSteps = document.getElementsByClassName('p-steps-item')

  if (clear) {
    for (let stepItem of allSteps) {
      stepItem.className = stepItem.className.replace(
        'p-completed',
        'p-disabled'
      )
    }
    return
  }
  // Style by order: before current step will be changed
  for (let stepItem of allSteps) {
    const stepIsSelected = stepItem.className.includes('p-highlight ')

    if (stepIsSelected) break
    stepItem.className = STEP_STYLE_BY_STATE.completed
  }
}

const filterRequiredFields = fieldRefs =>
  fieldRefs.reduce((acc, crr) => {
    // INPUT REF
    if (crr.current && crr.current.props && crr.current.props.required) {
      return [...acc, crr.current.element]
    }
    // all field comes with { value, name } type like dropdown/autocomplete
    else if (crr.required) {
      return [...acc, crr]
    }

    return acc
  }, [])

// fieldsRequiredInfo: { value, name }
export const checkRequiredFields = fieldRefs => {
  const fieldsNormalized = filterRequiredFields(fieldRefs)

  return fieldsNormalized
    .map(fieldInfo => {
      // Input related
      const inputRefRef = document.querySelector(`label[for=${fieldInfo.name}]`) // document.getElementsByName(fieldInfo.name)[0]

      if (fieldInfo.value) {
        if (inputRefRef.style.color === 'darkred') {
          inputRefRef.style.setProperty('color', '')
        }
        return false
      }

      inputRefRef.style.setProperty('color', 'darkred')
      inputRefRef.textContent = `* ${inputRefRef.textContent}`
      return true
    })
    .includes(true)
}

export const setPrevValues = (prevState, fieldRefs, customValidation) => () => {
  const shouldTreatValues =
    customValidation === undefined ? prevState : customValidation
  if (shouldTreatValues) {
    for (let field of fieldRefs) {
      // field is a uncontroled INPUT
      if (field.ref) {
        if (field.ref.current.input) {
          field.ref.current.input.value = prevState[field.name]
          field.ref.current = {
            ...field.ref.current,
            element: { value: prevState[field.name] }
          }
        }
        field.ref.current.element.value = prevState[field.name]
      }
      // field is any controled component
      else if (field.onChange) {
        field.onChange({ value: prevState[field.name] })
      }
    }
  }
}
