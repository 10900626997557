import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { CustomSteps, UnderStepsContainer } from '../../components'
import {
  FormAttachment,
  FormCompany,
  FormIdentity,
  FormReport,
  HeaderTitle,
  ShowProtocol
} from '../../containers'
import { mapAsync } from '../../utils/generals'
import {
  CONTAINER_FORM_KEYS,
  handleStepsStyles,
  STEPS_NUMS
} from '../../helpers/Complaint'
import { useGrowl } from '../../hooks/useGrowl'
import { SucessDialog } from './SucessDialog'
import {
  attachReportRecord,
  generateProtocol,
  sendReportData,
  fetchFile,
  cancelProtocol
} from '../../services/Api'
import Storage from '../../services/Storage'
// import { useFetch } from '../../hooks/usefetch';
import ReportTime from './ReportTime'

export const ComplaintContext = createContext({})

const formSteps = [
  { label: 'Protocolo' },
  { label: 'Identificação' },
  { label: 'Empresa' },
  { label: 'Relato' },
  { label: 'Anexo' }
]
// const protocolId = parseInt(Math.random() * 10000000, 10)
// const stepsIndex = Object.values(STEPS_NUMS).length

const ReportStorage = Storage('report')
const REPORT_KEY = 'activeReport'
const REPORT_TIMESTAMP_KEY = 'activeReportTime'

export default function ComplaintPage () {
  const [activeIndex, setActiveIndex] = useState(STEPS_NUMS.protocol)
  const [formData, setFormData] = useState({})
  const [protocolId, setProtocolId] = useState()
  const [sucessModal, setSucessModal] = useState(false)
  const [viewProtocol, setViewProtocol] = useState(false)
  const [modalText, setModalText] = useState('')
  const [loading, setLoading] = useState(false)
  const [reportTime, setReportTime] = useState(Date.now())
  const { showGenericFieldError } = useGrowl()
  // const { response } = useFetch({url: 'get protocol URL', options})

  useEffect(handleStepsStyles, [activeIndex])

  const resetForm = useCallback(() => {
    ReportStorage.clear()
    setFormData({})
    setActiveIndex(STEPS_NUMS.protocol)
    Protocol()
  }, [setActiveIndex, setFormData])

  const newProtocol = useCallback(
    async cancel => {
      if (cancel === true) {
        cancelProtocol(protocolId)
      }
      setFormData({})
      setProtocolId(null)
      setViewProtocol(false)
      handleStepsStyles(true)
      setActiveIndex(STEPS_NUMS.protocol)
      setSucessModal(false)
      return
    },
    [protocolId]
  )

  const Protocol = async () => {
    await ReportStorage.clear()
    handleStepsStyles(true)
    await ReportStorage.set(REPORT_KEY, { protocol: await generateProtocol() })
    await ReportStorage.set(REPORT_TIMESTAMP_KEY, Date.now())
    const activeReport = ReportStorage.get(REPORT_KEY)
    const time = ReportStorage.get(REPORT_TIMESTAMP_KEY)
    setProtocolId(activeReport.protocol)
    setReportTime(time)
  }
  const handleForm = useCallback(
    (indexToGo, payload) => {
      if (payload) {
        setFormData(crrFormState => {
          const nextFormState = { ...crrFormState, ...payload }
          return nextFormState
        })
      }

      setActiveIndex(indexToGo)
    },
    [setActiveIndex, setFormData]
  )

  const handleFileReport = useCallback(
    fileUploaded => {
      async function sendData () {
        const { filialId: filial } = formData.company
        const branch = (filial ? filial.name : formData.company.filial) || ''
        const branchId = (filial && filial.id) || ''
        console.log({ branch, branchId })
        const value = {
          hasIdentification: !!formData.identification,
          identification: {
            email: formData.identification.email,
            name: formData.identification.name,
            phone: formData.identification.phone
          },
          company: {
            client: { ...formData.company.companyName },
            address: {
              city: formData.company.city,
              branch,
              branchId,
              department: formData.company.department
            }
          },
          description: {
            evidence: formData.description.evidence,
            involved: formData.description.involved,
            isRelated: formData.description.isRelated || true,
            originType: formData.description.originType || '0800',
            problem: formData.description.problem,
            reportType: formData.description.reportType,
            witness: formData.description.witness
          },
          [CONTAINER_FORM_KEYS.file]: fileUploaded,
          reportType: formData.description.reportType,
          involved: formData.description.involved
        }
        try {
          setLoading(true)
          await sendReportData(protocolId, value)
          if (fileUploaded) {
            await mapAsync(fileUploaded, async file => {
              await fetchFile(
                file.getUploadUrl.uploadURL,
                file.item,
                file.item.type
              )
            })
            const sendBucket = fileUploaded.map(item => item.getUploadUrl)
            await attachReportRecord(protocolId, sendBucket)
          }
          if (fileUploaded) {
            setModalText('Sua Denúncia foi efetuada com sucesso')
          } else {
            setModalText(
              'Sua Denúncia foi encaminha para a lista de items para inserir o anexo'
            )
          }
          setLoading(false)
          setSucessModal(true)
        } catch (e) {
          console.log(e)
          setLoading(false)
          showGenericFieldError(true)
        }
      }
      sendData()
    },
    [formData, protocolId, showGenericFieldError]
  )

  const headerTitle = useMemo(() => {
    const companyForm = formData[CONTAINER_FORM_KEYS.company]
    const companyInfo =
      companyForm && companyForm.companyName
        ? ` - ${companyForm.companyName.name}`
        : ''
    return `Denúncia: ${protocolId || 'Gerar Protocolo'}${companyInfo}`
  }, [formData, protocolId])

  const contextValue = useMemo(
    () => ({
      activeIndex,
      handleForm,
      protocolId,
      formData,
      showGenericFieldError,
      reportTime
    }),
    [
      activeIndex,
      handleForm,
      formData,
      showGenericFieldError,
      protocolId,
      reportTime
    ]
  )

  return (
    <ComplaintContext.Provider value={contextValue}>
      <HeaderTitle
        cancel={true}
        title={headerTitle}
        protocol={protocolId}
        newProtocol={newProtocol}
      />
      <SucessDialog
        sucessModal={sucessModal}
        protocol={protocolId}
        newProtocol={newProtocol}
        modalText={modalText}
      />
      <CustomSteps model={formSteps} activeIndex={activeIndex} />

      <UnderStepsContainer>
        <ShowProtocol
          viewProtocol={viewProtocol}
          setViewProtocol={setViewProtocol}
          resetForm={resetForm}
        />
        <FormIdentity />
        <FormCompany />
        <FormReport />
        <FormAttachment
          handleFileReport={handleFileReport}
          loading={loading}
          viewProtocol={viewProtocol}
        />
      </UnderStepsContainer>
      <ReportTime />
    </ComplaintContext.Provider>
  )
}
