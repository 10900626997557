import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { DeleteContainer } from './Styled'

export const DeleteComplaint = props => {
  const hideModal = () => {
    props.setModalDelete(false)
  }
  const Footer = (
    <div>
      <Button
        label='Sim'
        icon='pi pi-check'
        className='p-button-success'
        onClick={props.DeleteComplaint}
      />
      <Button
        label='Não'
        icon='pi pi-times'
        className='p-button-danger'
        onClick={hideModal}
      />
    </div>
  )
  return (
    <Dialog
      modal={true}
      header='Deletar Protocolo'
      footer={Footer}
      visible={props.modalDelete}
      style={{ width: '50vw' }}
      onHide={hideModal}
    >
      <DeleteContainer>
        <p>
          Certeza que deseja deletar o protocolo:
          <span style={{ fontWeight: 'bold' }}>
            {' ' + props.complaint.protocol || ''}
          </span>
        </p>
      </DeleteContainer>
    </Dialog>
  )
}
