import { InputIcon } from './InputIcon'
import * as InputHooks from './inputHooks'
import { InputLabel } from './InputLabel'
import { TextArea } from './TextArea'

export const Inputs = {
  InputIcon,
  InputLabel,
  TextArea,
  ...InputHooks
}
