import React, { useState } from 'react'
import Proptype from 'prop-types'
import { Button } from 'primereact/button'
import {
  TitleContainer,
  Title,
  ContainerCenterContent,
  MainTitle
} from './styles'
import { MinLoading } from '../../components'

export const HeaderTitle = React.memo(props => {
  const [disabled, setDisabled] = useState(false)

  const _disabled = disabled || props.disabled

  const _handlButtonClick = async evt => {
    await setDisabled(true)
    await props.handleButtonClick(evt)
    setDisabled(false)
  }

  return (
    <TitleContainer>
      <ContainerCenterContent>
        <MainTitle>
          <Title data-testid='header-title' style={{ fontSize: '3vh' }}>
            {props.title}
          </Title>
          {props.cancel ? (
            <Button
              style={{ fontSize: 10, width: '12%', height: '5%' }}
              label='Cancelar Protocolo'
              onClick={() => props.newProtocol(true)}
              disabled={!props.protocol}
              //  tooltip={props.tooltip}
            />
          ) : null}
        </MainTitle>

        <ContainerCenterContent>
          <MinLoading loading={_disabled} label={props.loadingMessage} />
          {props.handleButtonClick && props.buttonLabel && (
            <Button
              disabled={_disabled}
              icon={`pi ${props.buttonIcon || 'pi-plus-circle'}`}
              label={props.buttonLabel}
              onClick={_handlButtonClick}
              tooltip={props.tooltip}
            />
          )}
        </ContainerCenterContent>
      </ContainerCenterContent>
    </TitleContainer>
  )
})

HeaderTitle.propTypes = {
  handleButtonClick: Proptype.func,
  title: Proptype.string.isRequired,
  titleStyle: Proptype.object,
  buttonLabel: Proptype.string,
  buttonIcon: Proptype.string,
  disabled: Proptype.bool,
  loadingMessage: Proptype.string
}
