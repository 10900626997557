import { getIdToken } from './Auth'

const apiConfig = {
  url:
    'https://w4ph4hribvbc5m6adeayehv5pa.appsync-api.us-west-2.amazonaws.com/graphql'
}

const fetchGraphql = query =>
  fetch(apiConfig.url, {
    method: 'POST',
    headers: {
      authorization: getIdToken()
    },
    body: JSON.stringify({ query })
  })
    .then(res => res.json())
    .then(res => {
      if (res.errors) {
        return Promise.reject(res.errors)
      }
      return res.data
    })

export const getProtocol = async protocol => {
  const { getProtocol: res } = await fetchGraphql(
    `query { getProtocol(protocol: "${protocol}") { protocol } }`
  )
  return res.protocol
}

export const generateProtocol = async () => {
  const { generateProtocol: res } = await fetchGraphql(
    'mutation { generateProtocol { protocol } }'
  )
  return res.protocol
}

export const getClients = async () => {
  const { clients } = await fetchGraphql(`{
    clients{
      items {
        name
        alias
        branches {
          id
          name
        }
      }
      nextToken
    }
  }`)
  return clients
}

export const sendReportData = async (protocol, data) => {
  console.log({ data })
  function getDataParameter () {
    const { company, description, reportType, identification } = data
    const hasIdentification = !!identification && !!identification.name
    const identificationParam = hasIdentification
      ? `identification: {
      name: "${identification.name || '-'}"
      email: "${identification.email || '-'}"
      phone: "${identification.phone || '-'}"
    }`
      : ''
    return `{
      company: {
        client: {
          alias: "${company.client.alias || '-'}"
	        name: "${company.client.name || '-'}"
        }
        address: {
          city: "${company.address.city || '-'}"
          branch: "${company.address.branch || ''}"
          branchId: "${company.address.branchId || ''}"
          department: "${company.address.department || '-'}"
        }
      }
      description: {
        relatedFacts: "-"
        isRelated: ${description.isRelated}
        involved: """${description.involved || '-'}"""
        witness: """${description.witness || '-'}"""
        problem: """${description.problem || '-'}"""
        evidence: """${description.evidence || '-'}"""
        observation: """${description.observation || '-'}"""
      }
      reportType: ${reportType || '-'}
      hasIdentification: ${hasIdentification || false}
      ${identificationParam}
    }`
  }
  const { sendReportData: res } = await fetchGraphql(`mutation {
    sendReportData(protocol: "${protocol}", data: ${getDataParameter()}) {
      protocol
    }
  }`)
  return res
}

export const attachReportRecord = async (protocol, data) => {
  const sendData = data.map(
    item => `{
    bucket: "${item.bucket}",
    key: "${item.key}"
  }`
  )
  const { attachReportRecord: res } = await fetchGraphql(`mutation {
    attachReportRecord(protocol: "${protocol}", data: [${sendData}]) {
      protocol
    }
  }`)
  return res
}

export const getUploadUrl = async (key, contentType, protocol) => {
  const res = await fetchGraphql(
    `mutation {
      getUploadUrl(key: "${key}", contentType: "${contentType}", protocol: "${protocol}"){
        uploadURL
        key
        bucket
    }}
    `
  )
  return res
}

export const getComplaintFinish = async (nextT, sub) => {
  const res = await fetchGraphql(
    `query {
      reports (filter: { reportStatus: {eq: "WAITING_EVIDENCE"}${
        typeof sub === 'string' ? `, user: {eq: "${sub}"}` : ''
      }}, nextToken: ${typeof nextT === 'string' ? `"${nextT}"` : null}) {
        items {
          protocol
          reportStatus
        }
        nextToken
      }
    }
    `
  )
  return res
}

export const cancelProtocol = async protocol => {
  const res = await fetchGraphql(
    `mutation {
      cancelReport(protocol: "${protocol}") {
        protocol
      }
    }`
  )
  return res
}

export const fetchFile = async (url, query, type) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', type)
  await fetch(url, {
    method: 'PUT',
    body: query,
    headers: myHeaders
  })
    .then(() => ({}))
    .then(res => {
      if (res.errors) {
        return Promise.reject(res.errors)
      }
      return res.data
    })
}
