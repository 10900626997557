import React, {
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useState
} from 'react'
import { ComplaintContext } from '../../../pages/complaintPage/ComplaintPage'
import { Button } from 'primereact/button'
import {
  STEPS_NUMS,
  CONTAINER_FORM_KEYS,
  checkRequiredFields,
  setPrevValues
} from '../../../helpers/Complaint'
import {
  Inputs,
  Centralize,
  GridMaxWidth,
  DropdownContainer,
  Wrapper,
  RadioButtonWrapper
} from '../../../components'
import { ButtonsCentered } from '../../buttonsCentered/ButtonsCentered'
// import { useFetch } from '../../hooks/usefetch';
import { getValueFromRefsByKey } from '../../../utils/generals'
import './FormCompany.css'
import { FIELDS } from '../../../helpers/Complaint'
import { getClients } from '../../../services/Api'
import Storage from '../../../services/Storage'

const { Dropdown, useDropdown } = DropdownContainer
const { useUncontrolledInput, InputLabel } = Inputs

const {
  inputCityProps,
  inputFilialProps,
  inputDepartmentProps
} = FIELDS.COMPANY

const { radioButton_yes, radioButton_no } = FIELDS.IDENTIFICATION

const { RadioButton } = RadioButtonWrapper

const DataStorage = Storage('data')
const CLIENTS_KEY = 'atualClients'

// COMPONENT
const CompanyForm = props => {
  // const { response, loading } = useFetch({url: 'company list UR', options})

  const [clients, setClients] = useState(
    DataStorage.get(CLIENTS_KEY) || { items: null, nextToken: null }
  )
  const [selectedClient, setSelectedClient] = useState(null)

  const radioButtonChecked = RadioButtonWrapper.useMultipleRadioButtons(
    radioButton_no.label
  )
  const radioButtonPositive = RadioButtonWrapper.useRadioButton({
    ...radioButton_yes,
    ...radioButtonChecked
  })
  const radioButtonNegative = RadioButtonWrapper.useRadioButton({
    ...radioButton_no,
    ...radioButtonChecked
  })

  // useEffect(() => {
  //   const prevCompanyForm = props.formData[CONTAINER_FORM_KEYS.company]

  //   if (prevCompanyForm) {
  //     companyField.onChange({ value: prevCompanyForm[companyField.name] })
  //     inputCity.ref.current.element.value = prevCompanyForm[inputCity.name]
  //     inputFilial.ref.current.element.value = prevCompanyForm[inputFilial.name]
  //     inputDepartment.ref.current.element.value = prevCompanyForm[inputDepartment.name]
  //   }

  //   /* eslint-disable-next-line */
  // }, [])

  useEffect(() => {
    async function firstClients () {
      const clientsResponse = await getClients()
      setClients(clientsResponse)
      DataStorage.set(CLIENTS_KEY, clientsResponse)
    }
    firstClients()
  }, [])

  const companyFieldProps = useMemo(
    () => ({
      label: 'Para qual Empresa ?',
      list: clients.items
        ? clients.items.map(client => ({ label: client.name, value: client }))
        : null,
      name: 'companyName',
      required: true,
      filterPlaceholder: 'Pesquisar clientes',
      filter: true,
      filterBy: 'name,alias',
      key: 'alias',
      onChange: ({ value }) => setSelectedClient(value)
    }),
    [clients]
  )

  const [filialFieldProps, setFilialFieldProps] = useState({
    label: 'Filial',
    list: [],
    name: 'filialId',
    required: true,
    filterPlaceholder: 'Pesquisar filiais',
    filter: true,
    filterBy: 'name',
    key: 'id'
  })

  const companyField = useDropdown(companyFieldProps)
  const inputCity = useUncontrolledInput(inputCityProps)
  const inputFilial = useUncontrolledInput(inputFilialProps)
  const dropdownFilial = useDropdown(filialFieldProps)
  const inputDepartment = useUncontrolledInput(inputDepartmentProps)

  useEffect(
    setPrevValues(props.formData[CONTAINER_FORM_KEYS.company], [
      companyField,
      inputCity,
      inputFilial,
      inputDepartment,
      dropdownFilial
    ]),
    []
  )

  useEffect(() => {
    setFilialFieldProps({
      label: 'Filial',
      list: ((selectedClient || {}).branches || []).map((c, index) => ({
        label: c.name,
        value: { ...c, index }
      })),
      name: 'filialId',
      required: true,
      filterPlaceholder: 'Pesquisar filiais',
      filter: true,
      filterBy: 'name',
      key: 'index'
    })
  }, [selectedClient])

  return (
    <>
      <Centralize>
        <GridMaxWidth>
          <Wrapper component={Dropdown} {...companyField} />
          <Wrapper component={InputLabel} componentProps={inputCity} />
          {((selectedClient || {}).branches || []).length > 0 && (
            <div className='p-col-12 p-md-12' style={{ textAlign: 'start' }}>
              <label>Inserir filial manualmente?</label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                <RadioButton
                  radioButtonProps={radioButtonNegative}
                  style={{ marginBottom: '7px' }}
                />
                <RadioButton radioButtonProps={radioButtonPositive} />
              </div>
            </div>
          )}
          {((selectedClient || {}).branches || []).length > 0 &&
          radioButtonChecked.value === radioButton_no.label ? (
            <Wrapper component={Dropdown} {...dropdownFilial} />
          ) : (
            <Wrapper component={InputLabel} componentProps={inputFilial} />
          )}
          <Wrapper component={InputLabel} componentProps={inputDepartment} />
        </GridMaxWidth>
      </Centralize>

      <ButtonsCentered>
        <Button
          label='Voltar'
          className='p-button-secondary'
          onClick={props.cancelCompanyForm}
        />
        <Button
          label='Continuar'
          onClick={() =>
            props.handleCompanyForm([
              companyField,
              inputCity.ref,
              radioButtonChecked.value === radioButton_no.label
                ? dropdownFilial
                : inputFilial.ref,
              inputDepartment.ref
            ])
          }
        />
      </ButtonsCentered>
    </>
  )
}

export function FormCompany () {
  const {
    activeIndex,
    handleForm,
    showGenericFieldError,
    formData
  } = useContext(ComplaintContext)

  const cancelCompanyForm = useCallback(() => {
    handleForm(STEPS_NUMS.identification)
  }, [handleForm])

  const handleCompanyForm = useCallback(
    fieldRefs => {
      if (checkRequiredFields(fieldRefs)) return showGenericFieldError()
      handleForm(STEPS_NUMS.report, {
        [CONTAINER_FORM_KEYS.company]: getValueFromRefsByKey(fieldRefs)
      })
    },
    [handleForm, showGenericFieldError]
  )

  if (activeIndex !== STEPS_NUMS.company) return null

  return (
    <CompanyForm
      handleCompanyForm={handleCompanyForm}
      cancelCompanyForm={cancelCompanyForm}
      formData={formData}
    />
  )
}
