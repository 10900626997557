export const ROUTE_NAMES = {
  loginPage: '/login',
  complaintPage: '/',
  reportFinish: '/concluir-denuncia'
}

export const CLASSES = {
  fullWidth: 'full-width'
}

// Growl ===> [severity, summary, detail, growlProps = {}]
export const MESSAGES = {
  ERROR: {
    gerericFieldError: [
      'error',
      'Erro ao finzalizar formulário',
      'Campos em vermelho não foram preenchidos devidamente.'
    ],
    errorSend: [
      'error',
      'Erro ao finzalizar formulário',
      'Sua denuncia não foi enviada'
    ]
  }
}
